<template>
  <div id="paymentCode">
    <div class="qrcode_wrapper">
      <img class="img-qrcode" :src="paymentCode" />
      <div class="text">长按保存图片</div>
    </div>
    <!-- 绑定音箱 -->
    <div class="voice-box">
      <div class="input_wrapper">
        <div class="text">音箱SN:</div>
        <van-field v-model="sn" name="音箱SN:" label :disabled="snBinded.length > 0" placeholder="请输入音箱SN" />
      </div>
      <div v-if="snBinded" class="action-btn_wrapper">
        <div class="btn btn_change-bind" @click="rebind">更换绑定</div>
        <div class="btn-gap"></div>
        <div class="btn btn_delete-bind" @click="deleteBind">解除绑定</div>
      </div>
      <div v-else class="action-btn_wrapper" @click="bind">
        <div class="btn btn_change-bind">绑定收款音箱</div>
      </div>
    </div>
  </div>
</template>
<script>
import { paymentCodeImageUrl, merchantInfo, postBindSoundDeviceSn } from "@/api/common";
export default {
  name: "PaymentCode",
  data() {
    return {
      paymentCode: "",
      sn: "",
      snBinded: ""
    };
  },
  created() {
    this.getPaymentCode();
    this.getMerchantInfo();
  },
  methods: {
    getPaymentCode() {
      paymentCodeImageUrl().then((res) => {
        this.paymentCode = res.data;
      });
    },
    getMerchantInfo() {
      merchantInfo().then((res) => {
        const data = res.data
        if (data) {
          this.sn = data.soundDeviceSn
          this.snBinded = data.soundDeviceSn
        }
      });
    },
    bind() {
      this.doBind(this.sn)
    },
    rebind() {
      this.sn = ''
      this.snBinded = ''
    },
    deleteBind() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要解除绑定吗?",
        })
        .then(() => this.doBind());
    },
    doBind(sn) {
      postBindSoundDeviceSn({ soundDeviceSn: sn }).then(res => {
        this.$toast({
          type: "success",
          message: `${sn ? '' : '解除'}绑定${res.code == 200 ? '成功' : '失败'}`,
          onClose: () => {
            if (res.code == 200) {

              this.getMerchantInfo()
            }
          },
        });
      })
    }
  },
};
</script>
<style lang="less">
.qrcode_wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  justify-content: center;
  align-items: center;

  .img-qrcode {
    width: 80%;
  }

  .text {
    margin-top: 20px;
  }
}

.voice-box {

  .input_wrapper {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 15px;
    margin: 24px 33px 20px 33px;

    .text {
      flex-shrink: 0;
      font-size: 14px;
      color: #333333;
    }
  }

  .action-btn_wrapper {
    display: flex;
    align-items: center;
    padding: 0px 33px;

    .btn {
      flex: 1;
      font-size: 13px;
    }

    .btn-gap {
      width: 10px;
      flex-shrink: 0;
    }

    .btn_change-bind {
      height: 40px;
      line-height: 40px;
      color: #FFF;
      background: linear-gradient(89deg, rgba(253, 110, 5, 0.66) 10%, #fd6e05 81%);
      border-radius: 5px;
      text-align: center;
    }

    .btn_delete-bind {
      height: 40px;
      line-height: 40px;
      border: 1px solid #fd6e05;
      border-radius: 5px;
      color: #fd6e05;
      text-align: center;
    }
  }
}
</style>
